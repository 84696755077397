/* ===================== TESTIMONIAL ======================*/
.testimonial-area {
	background: rgba(249, 249, 249, 0.76)
}

.testimonial-area .site-heading {
	text-align: center;
	margin-bottom: 50px
}

.single-testimonial {
	background: #fff;
	padding: 30px;
}

.testimonial-text img {
	float: left;
	width: 50px !important;
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	margin-top: -25px;
	margin-right: 10px;
}

.testimonial-meta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 20px
}

.testimonial-meta img {
	width: 70px !important;
	height: 70px;
	border-radius: 50%;
	margin-right: 20px;
}

.testimonial-author h4 {
	margin: 0;
	letter-spacing: 3px
}

.testimonial-author p {
	margin: 0
}

.tetimonial-slider .slide{
	padding: 0 10px;
}
.tetimonial-slider .slick-dots li{
	margin: 0 2px;
}
.tetimonial-slider .slick-dots li button:before{
	font-size: 12px;
	opacity: 0.2;
	color: #000;

}
.tetimonial-slider .slick-dots li.slick-active button:before{
	opacity: 1;
	color: #B78D65;
}
.tetimonial-slider .slick-dots{
	position: inherit;
	margin-top: 30px;
}

/*Responsive*/
@media(max-width: 991px) {
	.tetimonial-slider .slick-dots{
		bottom: 0;
	}
}