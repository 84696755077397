/* ===================== FEATURE ======================*/

.about-page-area .site-heading {
	margin-bottom: 50px
}

.about-page-area .about-right {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 30px
}

.about-page-area .single-about-image:first-child {
	padding-top: 43px
}

.about-icon {
	float: left;
	width: 80px;
	overflow: hidden;
	margin-right: 30px;
}

.single-about-listing {
	overflow: hidden;
	padding-bottom: 20px;
	margin-bottom: 30px;
	border-bottom: 1px solid #eee;
}

.single-about-listing:last-child {
	margin-bottom: 0
}
